

    import { computed, defineComponent, getCurrentInstance, nextTick, onBeforeMount, ref } from 'vue'
    import Container from '../components/Container.vue'
    import Column from '../components/Grid/Column.vue'
    import Grid from '../components/Grid/Grid.vue'
    import ThemeChanger from '../components/ThemeChanger.vue'
    import AdobeIcon from '../components/Logos/Adobe.vue'
    import AntDesignIcon from '../components/Logos/AntDesign.vue'
    import CtripIcon from '../components/Logos/Ctrip.vue'
    import GoogleIcon from '../components/Logos/Google.vue'
    import TencentIcon from '../components/Logos/Tencent.vue'
    import OpenSourcePackage from '../components/OpenSourcePackage.vue'
    import OpenSourceSkeleton from '../components/OpenSourceSkeleton.vue'
    import FilterButton from '../components/FilterButton.vue'
    import { useApi } from '../compositions/UseApi'
    import { currentActiveBreakPoint } from '../compositions/UseResponsiveValue'
    import { useCms } from '../compositions/UseCms'
    import { Breakpoint } from '../enums/Breakpoint'
    import { Theme } from '../enums/Theme'
    import { useTheme } from '../compositions/UseTheme'
    import { useMobileMenu } from '../compositions/menu/UseMobileMenu'
    import { stickElementOnScroll } from '../compositions/behaviors/StickElementOnScroll'
    import MetaInfo from '../components/MetaInfo.vue'
    import PageHeader from '../components/PageHeader.vue'
    import Heading from '../components/Heading.vue'

    export default defineComponent({
        name: 'OpenSource',
        components: {
            MetaInfo,
            Grid,
            Column,
            Heading,
            Container,
            OpenSourcePackage,
            FilterButton,
            OpenSourceSkeleton,
            ThemeChanger,
            PageHeader
        },
        setup() {

            useTheme().initializeCurrentThemeBasedOnRouteMeta()

            const { openSource, isCNLocale } = useCms()
            const { show } = useMobileMenu()
            const api = useApi()

            const { wrapperReference, stickyElementReference, offsetStyle, isSticky } = stickElementOnScroll({
                top: 0,
                bottom: 0
            })

            const categories = ref<any[]>([])
            const packages = ref<any[]>([])
            const featured = ref<any[]>([])
            const selectedTechnology = ref<string>('all')
            const isLoading = ref<boolean>(true)
            const refreshAOS = getCurrentInstance()?.appContext.config.globalProperties.$aos?.refresh

            onBeforeMount(async() => {

                Promise.all([ api.openSourceMetrics(isCNLocale.value ? 'cn' : 'en'), api.openSourceCategories(isCNLocale.value ? 'cn' : 'en') ]).then((responses) => {

                    if (responses[0].isOk()) {

                        for (const item of responses[0].value.data) {

                            packages.value.push(item)

                            item.featured && featured.value.push(item)

                        }

                    }

                    if (responses[1].isOk()) {

                        categories.value = [
                            {
                                title: 'All',
                                slug: 'all'
                            },
                            ...responses[1].value.data
                        ]

                    }

                    isLoading.value = false

                    nextTick(() => {

                        refreshAOS?.()

                    })

                })

            })

            return {
                Theme,
                cms: openSource,
                wrapperReference,
                stickyElementReference,
                offsetStyle,
                isSticky,
                categories,
                packages,
                featured,
                show,
                selectedTechnology,
                isLoading,
                isCNLocale,
                isSmall: computed(() => currentActiveBreakPoint.value === Breakpoint.SM),
                filteredPackages: computed(() => {

                    if (selectedTechnology.value === 'all') {

                        return packages.value

                    }

                    return packages.value.filter(pckg => pckg.category.slug === selectedTechnology.value)

                }),
                setSelectedTechnology(tech: string) {

                    selectedTechnology.value = tech

                    nextTick(() => {

                        refreshAOS?.()

                    })

                },
                logos: [
                    GoogleIcon,
                    AdobeIcon,
                    TencentIcon,
                    CtripIcon,
                    AntDesignIcon
                ]
            }

        }
    })

