import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  ref: "wrapperReference",
  class: "open-source__wrapper"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "space-x-5 sm:flex sm:flex-nowrap"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "open-source__package-count text-base" }
const _hoisted_6 = {
  key: 2,
  class: "pt-16 md:pt-24 space-y-16 md:space-y-24"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_OpenSourceSkeleton = _resolveComponent("OpenSourceSkeleton")!
  const _component_Heading = _resolveComponent("Heading")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Grid = _resolveComponent("Grid")!
  const _component_Container = _resolveComponent("Container")!
  const _component_OpenSourcePackage = _resolveComponent("OpenSourcePackage")!
  const _component_FilterButton = _resolveComponent("FilterButton")!
  const _component_ThemeChanger = _resolveComponent("ThemeChanger")!
  const _component_MetaInfo = _resolveComponent("MetaInfo")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ThemeChanger, {
      to: _ctx.Theme.Blue
    }, {
      default: _withCtx(() => [
        _createVNode(_component_PageHeader, {
          class: "mt-32 lg:mt-64",
          header: _ctx.cms.heading,
          "header-span": [ 2, 5 ],
          "sub-header": _ctx.cms.description
        }, null, 8, ["header", "sub-header"]),
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_OpenSourceSkeleton, {
              key: 0,
              class: "mt-12 md:mt-24"
            }))
          : (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_Container, {
                fluid: "",
                class: "pb-0 mt-12 md:mt-24"
              }, {
                default: _withCtx(() => [
                  (_ctx.featured.length)
                    ? (_openBlock(), _createBlock(_component_Grid, { key: 0 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Column, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_Heading, { tag: "h2" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.isCNLocale ? '精选' : 'Featured'), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              (_ctx.featured.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.featured, (openSourcePackage, idx) => {
                      return (_openBlock(), _createBlock(_component_OpenSourcePackage, {
                        key: `${openSourcePackage.title}-${idx}`,
                        data: openSourcePackage,
                        featured: "",
                        class: _normalizeClass([ idx > 0 ? 'mt-24' : 'mt-16' ])
                      }, null, 8, ["data", "class"]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_Container, { fluid: "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Grid, {
                    columns: 6,
                    class: "open-source__sticky-wrapper md:mt-36"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Column, {
                        span: 6,
                        class: _normalizeClass(["open-source__sticky", { '--stickied': _ctx.isSticky }]),
                        style: _normalizeStyle(_ctx.offsetStyle)
                      }, {
                        default: _withCtx(() => [
                          (_ctx.categories.length)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (item) => {
                                  return (_openBlock(), _createElementBlock("button", {
                                    key: item.slug,
                                    class: _normalizeClass(["open-source__technology whitespace-nowrap", { '--active': _ctx.selectedTechnology === item.slug }]),
                                    onClick: ($event: any) => (_ctx.setSelectedTechnology(item.slug))
                                  }, _toDisplayString(item.title), 11, _hoisted_4))
                                }), 128))
                              ]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["style", "class"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Grid, { class: "sm:mt-4 mt-8" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Column, null, {
                        default: _withCtx(() => [
                          (_ctx.categories.length && !_ctx.show)
                            ? (_openBlock(), _createBlock(_component_FilterButton, {
                                key: 0,
                                class: "md:hidden",
                                filters: _ctx.categories,
                                onChange: _ctx.setSelectedTechnology
                              }, null, 8, ["filters", "onChange"]))
                            : _createCommentVNode("", true),
                          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.isCNLocale ? `已显示${_ctx.filteredPackages.length}项目` : `Showing ${_ctx.filteredPackages.length} projects/plugins`), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ], 512)),
        (_ctx.filteredPackages.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredPackages, (openSourcePackage, idx) => {
                return (_openBlock(), _createBlock(_component_OpenSourcePackage, {
                  key: `${openSourcePackage.title}-${idx}`,
                  data: openSourcePackage
                }, null, 8, ["data"]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["to"]),
    (_ctx.cms.seo)
      ? (_openBlock(), _createBlock(_component_MetaInfo, {
          key: 0,
          seo: _ctx.cms.seo,
          "structured-data": _ctx.cms.seo_structured_data
        }, null, 8, ["seo", "structured-data"]))
      : _createCommentVNode("", true)
  ], 64))
}